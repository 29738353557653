<template>
  <el-form ref="form" :model="form" :rules="rules" @submit.native.prevent="onSubmitFilter" class="form__filter-traveling w-100">
    <!-- Project -->
    <el-form-item label="Projekt">
      <div class="flex">
        <select v-model="form.project" class="w-100">
          <option :value="project.id" v-for="project in projects" :key="project.id">
            <span v-html="project.name"></span>
          </option>
        </select>

        <el-button class="m-l-half" type="default" plain icon="el-icon-delete" @click="form.project = 0"></el-button>
      </div>
    </el-form-item>

    <!-- Company -->
    <el-form-item label="Firma">
      <div class="flex">
        <select v-model="form.company" class="w-100">
          <option :value="company.id" v-for="company in companies" :key="company.id">
            <span v-html="company.name"></span>
          </option>
        </select>

        <el-button class="m-l-half" type="default" plain icon="el-icon-delete" @click="form.company = 0"></el-button>
      </div>
    </el-form-item>

    <!-- Start/End -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="12">
        <el-form-item label="Start">
          <el-input v-model="form.start" type="date" placeholder="dd.MM.YYYY"></el-input>
        </el-form-item>
      </el-col>

      <el-col :xs="24" :sm="12">
        <el-form-item label="Ende">
          <el-input v-model="form.end" type="date" placeholder="dd.MM.YYYY"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <!-- Submit -->
    <el-form-item class="m-t-3 m-b-0">
      <el-button type="primary" native-type="submit" :loading="loading" class="w-100">Filtern</el-button>
      <el-button type="default" plain @click="clearFilter" class="w-100 m-t-half" style="margin-left: 0;">Filtern löschen</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { addDays, endOfDay, format, startOfMonth } from 'date-fns';

export default {
  name: 'FormFilterTraveling',

  data() {
    return {
      form: {
        project: null,
        company: null,
        start: null,
        end: null
      },
      loading: false,
      rules: {
        start: [{ type: 'date', required: true, message: 'Bitte Startdatum eingeben', trigger: 'blur' }],
        end: [{ type: 'date', required: true, message: 'Bitte Enddatum eingeben', trigger: 'blur' }]
      }
    };
  },

  computed: {
    companies() {
      return this.$store.state.company.all;
    },

    filter() {
      return this.$store.state.travel.filter;
    },

    projects() {
      return this.$store.state.project.all;
    },

    user() {
      return this.$store.state.user.current;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    clearFilter() {
      this.form.project = null;
      this.form.company = null;
      this.form.start = null;
      this.form.end = null;

      this.$store.commit('travel/CLEAR_FILTERS');
    },

    init() {
      Object.assign(this.form, this.filter);
      if (this.form.start) {
        this.form.start = format(new Date(this.form.start), 'yyyy-MM-dd');
      } else {
        this.form.start = format(startOfMonth(new Date()), 'yyyy-MM-dd');
      }

      if (this.form.end) {
        this.form.end = format(new Date(this.form.end), 'yyyy-MM-dd');
      } else {
        this.form.end = format(addDays(new Date(), 1), 'yyyy-MM-dd');
      }
    },

    onSubmitFilter() {
      const start = this.form.start ? endOfDay(new Date(this.form.start)) : endOfDay(new Date());
      const end = this.form.end ? endOfDay(new Date(this.form.end)) : endOfDay(new Date());

      const DATA = Object.assign({}, this.form);
      DATA.start = format(start, "yyyy-MM-dd'T'HH:mm:");
      DATA.end = format(end, "yyyy-MM-dd'T'HH:mm:");
      DATA.user = this.user.id;

      this.$store.commit('travel/SET_FILTERS', DATA);
      this.requestTravelingResults();
    },

    requestTravelingResults() {
      this.loading = true;

      return this.$store.dispatch('travel/GET_TRAVELING_RESULTS').finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss">
.form__filter-traveling {
  .el-form-item__label {
    display: block !important;
    float: none;
    text-align: left;
  }
}
</style>
